import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$setup.showUnlockedView)
    ? (_openBlock(), _createBlock($setup["SubprocessorForm"], {
        key: 0,
        "form-settings-key": $props.formSettingsKey,
        "widget-data": $props.widgetData,
        "custom-data": $props.customData
      }, null, 8 /* PROPS */, ["form-settings-key", "widget-data", "custom-data"]))
    : _createCommentVNode("v-if", true)
}