<script setup lang="ts">

  import type { FormWidgetData } from '../../../widgetDataTs.js';
  import type { ContactFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import SubprocessorForm from "../form-element/subprocessor-form-base.vue";
  import { useFormServices } from "../../composables/use-form-services.js";
  import { computed } from "vue";

  defineProps<{
    formSettingsKey: ContactFormSettingsKey;
    widgetData: FormWidgetData<"ContactForm">;
    customData?: Record<string, any>;
  }>();
  const { FormState } = useFormServices({ callerLabel: "subprocessorForm" });

  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete";
  });
</script>

<template>
  <template v-if="!showUnlockedView">
    <SubprocessorForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData" /> 
  </template>
</template>